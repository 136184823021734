import i18n from '@/i18n'
import { YearMonth } from '@/models/calendar.model'
import { OrderOverviewStatus, StatusListItem } from '@/models/google.model'
import { Interval } from '@/models/shared.model'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import endOfDay from 'date-fns/endOfDay'
import endOfMonth from 'date-fns/endOfMonth'
import getOverlappingDaysInIntervals from 'date-fns/getOverlappingDaysInIntervals'
import isSameDay from 'date-fns/isSameDay'
import isWithinInterval from 'date-fns/isWithinInterval'
import startOfDay from 'date-fns/startOfDay'

const { t } = i18n.global

export interface IntervalComparisonData {
  /** Start of the interval */
  startDay: Date
  /** Earliest date of interval which is found in comparison interval */
  earliest: Date | null
  /**
   * Whether startDay === earliest.
   * False if interval starts before comparison interval
   */
  earliestIsStart: boolean
  /** End of interval */
  endDay: Date
  /** Latest date of interval which is found in comparison interval */
  latest: Date | null
  /** Whether endDay === latest. False if interval ends after comparison interval */
  latestIsEnd: boolean
  /** Number of interval days found in comparison interval */
  overlappingDays: number
}

export const getIntervalComparisonData = (
  interval: Interval,
  comparisonInterval: Interval,
): IntervalComparisonData => {
  // getFullTimeInterval on comparisonInterval to makse sure last date overlap is not ignored because of time
  const overlappingDays = getOverlappingDaysInIntervals(
    interval,
    getFullTimeInterval(comparisonInterval),
  )
  const _intervalDays = eachDayOfInterval(interval)

  const startDay = startOfDay(interval.start)
  const endDay = endOfDay(interval.end)

  const _isWithinComparison = (day: Date): boolean =>
    isWithinInterval(day, comparisonInterval)
  // loops through each interval day until it finds first match
  const earliest = _intervalDays.find(_isWithinComparison) || null

  // reversed the list because latest should find first match from the bacl
  const _reversedIntervalDays = [..._intervalDays].reverse()
  const latest = _reversedIntervalDays.find(_isWithinComparison) || null

  return {
    startDay,
    earliest,
    earliestIsStart: earliest ? isSameDay(earliest, startDay) : false,
    endDay,
    latest,
    latestIsEnd: latest ? isSameDay(latest, endDay) : false,
    overlappingDays,
  }
}

export const getFullTimeInterval = (interval: Interval): Interval => {
  /**
   * Takes in an interval and returns a new interval where:
   * - Start time is set to start of day (00:00:00:000)
   * - End time is set to end of day (23:59:59:999)
   */
  return {
    start: startOfDay(interval.start),
    end: endOfDay(interval.end),
  }
}

export const getTranslatedStatus = (status: OrderOverviewStatus) => {
  switch (status) {
    case OrderOverviewStatus.CONFIRMED_ORDER:
      return t('overviewCalendar.orderType.CONFIRMED_ORDER')
    case OrderOverviewStatus.COMPLETED:
      return t('overviewCalendar.orderType.COMPLETED')
    case OrderOverviewStatus.EXPIRED:
      return t('overviewCalendar.orderType.EXPIRED')
    case OrderOverviewStatus.OFFER:
      return t('overviewCalendar.orderType.OFFER')
    case OrderOverviewStatus.LOCKED:
      return t('overviewCalendar.orderType.LOCKED')
    case OrderOverviewStatus.CONFIRMED_OFFER:
      return t('overviewCalendar.orderType.CONFIRMED_OFFER')
  }
}

export const calendarStatusColors: {
  [key in OrderOverviewStatus]: [string, string, string]
} = {
  [OrderOverviewStatus.OFFER]: ['#009DA7', '#C7FCFF', '#48d9e1'],
  [OrderOverviewStatus.EXPIRED]: ['#999A9A', '#F9F9F9', '#c9c9c9'],
  [OrderOverviewStatus.CONFIRMED_OFFER]: ['#7D6200', '#FFF7CD', '#f1d561'],
  [OrderOverviewStatus.CONFIRMED_ORDER]: ['#1B6E28', '#E5FFE4', '#77b277'],
  [OrderOverviewStatus.LOCKED]: ['#38007c', '#efe4ff', '#b59bde'],
  [OrderOverviewStatus.COMPLETED]: ['#004476', '#D8F1FF', '#91bbd3'],
}

export const mapStatusEnumToStatusFilteringList = (): StatusListItem[] => {
  return Object.values(OrderOverviewStatus).map((status, index) => ({
    id: index + 1 + '',
    status: status,
    active: true,
    color: calendarStatusColors[status][0],
    backgroundColor: calendarStatusColors[status][1],
  }))
}

export const getYearMonthAsInterval = (yearMonth: YearMonth): Interval => {
  const startOfMonth: Date = new Date(yearMonth.year, yearMonth.month, 1)
  return {
    start: startOfMonth,
    end: endOfMonth(startOfMonth),
  }
}

export const getYearMonthForDate = (
  date: Date | null | undefined,
): YearMonth => {
  date = date ?? new Date()
  return {
    year: date.getFullYear(),
    month: date.getMonth(),
  }
}

export const getNextYearMonth = (yearMonth: YearMonth): YearMonth => {
  const { year, month } = yearMonth
  if (month === 11) {
    return { year: year + 1, month: 0 }
  }
  return { year, month: month + 1 }
}

export const getPreviousYearMonth = (yearMonth: YearMonth): YearMonth => {
  const { year, month } = yearMonth
  if (month === 0) {
    return { year: year - 1, month: 11 }
  }
  return { year, month: month - 1 }
}
