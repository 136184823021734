<template>
  <div class="page">
    <sidebar-menu
      v-if="showSidebarAndHeader && selectedCompany"
      id="menu"
      theme="white-theme"
      :class="{ 'sidebar-menu--collapsed': mobileSidebarCollapsed }"
      :disable-hover="true"
      :hide-toggle="true"
      :menu="menu"
      :width="isMobile ? '258px' : '249px'"
      @item-click="mobileSidebarCollapsed = true"
    >
      <template #header>
        <div
          class="sidebar-menu__header"
          :style="{ opacity: selectedCompany ? 1 : 0 }"
        >
          <CompanyLogo />
          <div class="sidebar-menu__name">
            {{ selectedCompany?.company.name || '-' }}

            <BaseButton
              :class="{
                'sidebar-menu__company-toggle': true,
                'sidebar-menu__company-toggle--open': companySwitcherVisible,
              }"
              :format="FORMATS.ICON"
              @click="companySwitcherVisible = !companySwitcherVisible"
            >
              <BaseIcon icon="chevron-down" :size="16" />
            </BaseButton>
          </div>
          <div class="sidebar-menu__email">
            {{ selectedCompany?.company.email || '-' }}
          </div>

          <CompanySwitcher
            v-if="companySwitcherVisible"
            :user-companies="authStore.userCompanies"
          />
        </div>
      </template>

      <template #footer>
        <SelectLanguage class="hide-desktop sidebar-menu__lang" />
      </template>
    </sidebar-menu>
    <div
      v-if="isMobile && !mobileSidebarCollapsed"
      class="page__mobile-overlay"
      @click="mobileSidebarCollapsed = true"
    />
    <div
      :class="{
        content: true,
        'content--with-menu': showSidebarAndHeader && !isMobile,
      }"
    >
      <header v-if="showSidebarAndHeader" class="header">
        <BaseButton
          :format="FORMATS.TERTIARY"
          class="header__mobile-menu-button"
          @click="mobileSidebarCollapsed = false"
        >
          <BaseIcon icon="menu" :size="32" />
        </BaseButton>
        <router-link
          :to="{ name: ROUTE_PATHS.OVERVIEW }"
          class="header__logo-link"
        >
          <TouringeryLogo :width="102" :height="20" :icon="'logo-provider'" />
        </router-link>
        <ERFLogo style="max-height: 60px; width: min-content" />

        <SelectLanguage class="hide-mobile" />
        <div ref="userSettingsMenuTarget">
          <BaseButton
            class="header__user-avatar"
            data-test-id="user-avatar"
            :format="FORMATS.ICON"
            @click="userSettingsMenuVisible = !userSettingsMenuVisible"
            >{{ userInitials }}
          </BaseButton>
          <UserSettingsMenu v-if="userSettingsMenuVisible" />
        </div>
      </header>

      <div class="page__alerts">
        <RequiredSettingsOverviewAlert
          v-if="selectedCompany"
          :model-value="selectedCompany?.company"
          style="border-radius: 0px"
        />
      </div>

      <div class="content__inner">
        <slot />
      </div>

      <footer class="content__footer">
        <span>© 2024 Touringery OÜ</span>
        <div class="content__footer-right">
          <a
            class="content__footer-link content__footer-text"
            href="https://touringery.ee/"
            target="_blank"
            >{{ $t('contactUs') }}</a
          >
          <div class="content__footer-terms">
            <router-link
              class="content__footer-link content__footer-text"
              :to="{ name: ROUTE_PATHS.TERMS }"
            >
              {{ $t('termsOfService') }}
            </router-link>
            <router-link
              class="content__footer-link content__footer-text"
              :to="{ name: ROUTE_PATHS.PRIVACY_POLICY }"
            >
              {{ $t('privacyPolicy') }}
            </router-link>
          </div>
          <router-link to="/" class="content__footer-link">
            <TouringeryLogo
              :width="102"
              :height="20"
              :icon="'logo-with-name'"
              class="content__footer-logo"
            />
          </router-link>
        </div>
      </footer>
    </div>
  </div>
</template>

<script setup>
import { FORMATS } from '@/enums/components'
import { useAuthStore } from '@/store/auth'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, ref } from 'vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import TouringeryLogo from '@/components/TouringeryLogo.vue'
import ERFLogo from '@/components/ERFLogo.vue'
import SelectLanguage from '@/components/SelectLanguage.vue'
import RequiredSettingsOverviewAlert from '@/components/settings/RequiredSettingsOverviewAlert'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useBreakpoints } from '@/composables/useBreakpoints'
import CompanySwitcher from '@/components/CompanySwitcher'
import UserSettingsMenu from '@/components/UserSettingsMenu'
import { ROUTE_PATHS } from '@/router/paths'
import { ROUTE_PATHS as ADMIN_ROUTE_PATHS } from '@/router/admin/paths'
import { BASE_ACCESS_ROUTE_PATH } from '@/router/accessPaths'
import { onClickOutside } from '@vueuse/core'
import CompanyLogo from '@/components/CompanyLogo'
import { PERMISSIONS } from '@/enums/settings'

const router = useRouter()
const route = useRoute()
const i18n = useI18n()
const { isMobile } = useBreakpoints()
const mobileSidebarCollapsed = ref(true)
const companySwitcherVisible = ref(false)
const userSettingsMenuVisible = ref(false)
const authStore = useAuthStore()
const {
  isAuthenticated,
  selectedCompany,
  user,
  rolePermissions,
  canReadTickets,
  canUpdateTickets,
  canViewPropertyChannel,
} = storeToRefs(authStore)
const showSidebarAndHeader = computed(
  () => isAuthenticated.value && !BASE_ACCESS_ROUTE_PATH.includes(route.path),
)

onBeforeMount(async () => {
  // This component requires user data so we make sure to fetch it
  // if user was pre-authenticated from earlier login and user data not yet loaded.
  if (isAuthenticated.value && authStore.user === null) {
    await authStore.getUser()
  }

  if (authStore.userCompanies && !authStore.selectedCompany) {
    router.push({
      name: ROUTE_PATHS.CHOOSE_COMPANY,
      query: {
        next: route.path,
      },
    })
  }
})

const userInitials = computed(() => {
  return user.value?.first_name && user.value?.last_name
    ? user.value?.first_name
        .slice(0, 1)
        .concat(user.value?.last_name.slice(0, 1))
    : 'XX'
})
//! Menu items
const menu = computed(() => [
  {
    href: { name: ROUTE_PATHS.INVOICES },
    title: i18n.t('billing'),
    disabled: true,
    hidden: true,
    icon: {
      element: BaseIcon,
      attributes: { icon: 'invoices', size: 24 },
    },
  },
  {
    href: { name: ROUTE_PATHS.OVERVIEW },
    title: i18n.t('overview'),
    hidden: !rolePermissions.value?.includes(PERMISSIONS.READ_CALENDAR),
    icon: {
      element: BaseIcon,
      attributes: { icon: 'calendar-blank', size: 24 },
    },
  },
  {
    href: { name: ROUTE_PATHS.SERVICES },
    title: i18n.t('services'),
    hidden: !rolePermissions.value?.includes(PERMISSIONS.READ_SERVICES),
    icon: {
      element: BaseIcon,
      attributes: { icon: 'services', size: 24 },
    },
  },
  {
    href: { name: ROUTE_PATHS.SETTINGS_PROFILE },
    title: i18n.t('settings.settings'),
    hidden: !rolePermissions.value?.includes(PERMISSIONS.READ_COMPANY_SETTINGS),
    icon: {
      element: BaseIcon,
      attributes: { icon: 'settings', size: 24 },
    },
  },
  {
    href: { name: ROUTE_PATHS.CLIENTS },
    title: i18n.t('clients'),
    hidden: !rolePermissions.value?.includes(PERMISSIONS.READ_CLIENTS),
    class: 'top-border',
    icon: {
      element: BaseIcon,
      attributes: { icon: 'clients', size: 24 },
    },
  },
  {
    href: { name: ROUTE_PATHS.RESOURCES },
    title: i18n.t('resources'),
    hidden: !rolePermissions.value?.includes(PERMISSIONS.READ_RESOURCES),
    icon: {
      element: BaseIcon,
      attributes: { icon: 'resources', size: 24 },
    },
  },
  {
    href: { name: ROUTE_PATHS.REPORTS },
    title: i18n.t('finance'),
    hidden: !rolePermissions.value?.includes(PERMISSIONS.READ_REPORTS),
    icon: {
      element: BaseIcon,
      attributes: { icon: 'book', size: 24 },
    },
  },
  {
    href: { name: ADMIN_ROUTE_PATHS.ADMIN_BASE },
    title: i18n.t('admin.header'),
    hidden: !authStore.isAdminUser,
    icon: {
      element: BaseIcon,
      attributes: { icon: 'security-key', size: 24 },
    },
  },
  {
    href: { name: ROUTE_PATHS.SUPPORT_CONTACT },
    title: i18n.t('support.support'),
    icon: {
      element: BaseIcon,
      attributes: { icon: 'support', size: 24 },
    },
    class: 'top-border',
  },
])

const userSettingsMenuTarget = ref(null)
onClickOutside(
  userSettingsMenuTarget,
  () => (userSettingsMenuVisible.value = false),
)
</script>

<style lang="scss" scoped>
@import '@/assets/scss/typography.scss';

.page {
  height: 100%;
  position: relative;
  background-color: $background;

  &__mobile-overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
  }
}

.sidebar-menu {
  &__header {
    border-bottom: 1px solid $line;
    display: flex;
    flex-direction: column;
    padding: $sp-16;

    @at-root .vsm_collapsed & {
      padding: $sp-24 $sp-12 90px;
    }
  }

  &__avatar {
    align-items: center;
    align-self: center;
    background-color: #3e9af6;
    border-radius: $sp-56;
    color: $white;
    display: flex;
    height: $sp-56;
    justify-content: center;
    margin-bottom: $sp-8;
    padding: 6px;
    width: $sp-56;

    @at-root .vsm_collapsed & {
      font-size: 8px;
      height: 32px;
      padding: $sp-4;
      width: 32px;
    }
  }

  &__name {
    @extend %headline-4;
    display: flex;
    margin-bottom: 2px;

    @at-root .vsm_collapsed & {
      display: none;
    }
  }

  &__company-toggle {
    align-self: center;
    margin-left: auto;
    transition: 200ms ease-in-out transform;

    &--open {
      transform: rotate(-180deg);
    }
  }

  &__email {
    @extend %body-3;
    color: $label;

    @at-root .vsm_collapsed & {
      display: none;
    }
  }

  &__lang {
    margin: $sp-16 auto;
  }
}

.header {
  background-color: $white;
  border-bottom: 1px solid $line;
  display: flex;
  align-items: center;
  gap: $sp-24;
  height: 65px;
  padding: $sp-16;
  position: relative;
  z-index: 1;

  &__logo-link {
    height: 20px;
    margin-right: auto;
    color: $primary-dark;
  }

  &__mobile-menu-button {
    border-radius: 0;
    padding: 0;

    @include desktop {
      display: none;
    }
  }

  &__user-avatar {
    align-items: center;
    align-self: center;
    background-color: $tertiary-light;
    border-radius: $sp-32;
    color: $black;
    display: flex;
    height: $sp-32;
    justify-content: center;
    width: $sp-32;
    text-decoration: none;
    font-size: 16px;

    &:hover {
      color: $black;
      background-color: $tertiary;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  align-items: stretch;
  transition: 200ms ease-in-out margin-left;

  &--with-menu {
    margin-left: 249px;
  }

  &__inner {
    padding: $sp-16;
    flex: 1;
  }

  &__footer {
    background-color: $body;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $sp-24 $sp-16;
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/typography.scss';

$primary-color: white;
@import 'vue-sidebar-menu/src/scss/vue-sidebar-menu.scss';

// Specificity hell: some menu items already have 5 classes in the library code,
// so id is used to enable overriding them.
#menu {
  border-right: 1px solid $line;
  overflow: hidden;
  transition: 200ms ease-in-out transform;

  &.sidebar-menu {
    transform: translateX(0);

    &--collapsed {
      @include mobile {
        transform: translateX(-100%);
      }
    }
  }

  .vsm {
    &--item {
      margin-top: $sp-8;

      .top-border {
        border-top: 1px solid $line;
        padding-top: $sp-8;
      }
    }

    &--link {
      @extend %body-2;
      color: $body;
      line-height: 1.5;
      padding: $sp-8 $sp-16;

      &:hover {
        background-color: $disabled;
        border-radius: 4px;
        margin: 0 $sp-8;
        padding: $sp-8;
      }
    }

    &--link_active {
      background-color: $primary-light-2;
      border-radius: 4px;
      box-shadow: none;
      color: $primary-dark;
      font-weight: 400;
      margin: 0 $sp-8;
      padding: $sp-8;

      .vsm--icon {
        color: $primary-dark;

        rect {
          // Ignored for some reason?
          fill: $primary-light;
        }
      }
    }

    &--icon {
      background-color: transparent;
      height: 24px;
      width: 24px;
      fill: transparent;
    }
  }
}

.content__footer {
  background-color: $body;
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $sp-24 160px;
  gap: $sp-16;
  @include mobile {
    padding: $sp-24 $sp-16;
    flex-direction: column;
    gap: $sp-24;
  }

  &-link {
    text-decoration: none;
    color: $white;

    &:hover {
      color: $primary;
    }
  }

  &-logo {
    color: $white;
  }

  &-right {
    display: flex;
    gap: $sp-32;
    align-items: center;

    @include mobile {
      flex-direction: column;
      gap: $sp-8;
      text-align: center;
    }
  }

  &-terms {
    display: flex;
    flex-direction: column;
  }

  &-text {
    @extend %body-2;
  }

  &-logo {
    vertical-align: text-bottom;
  }
}

.erf-logo-wrapper {
}
</style>
