import {
  CompanyHousingDateAvailability,
  EventTimeSlot,
  MinimumTimeslot,
} from '@/models/public/service.model'
import { ITicketItemDateAvailabilityTimeslot } from '@/models/service/serviceTicket.model'
import { addMinutes, differenceInMinutes } from 'date-fns'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import endOfDay from 'date-fns/endOfDay'
import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import isSameDay from 'date-fns/isSameDay'
import startOfDay from 'date-fns/startOfDay'
import _ from 'lodash'

export const isHousingDateAvailable = (
  day: Date,
  availabilities: CompanyHousingDateAvailability[],
  selectedStartDate: Date | null,
  selectedEndDate: Date | null,
) => {
  const unavailableDay = availabilities.some(
    (availability: CompanyHousingDateAvailability) =>
      format(day, 'yyyy-MM-dd') === availability.date &&
      !availability.is_available,
  )
  // This date is in general unavailable
  if (unavailableDay) return false

  if (selectedStartDate && !selectedEndDate) {
    const period = isBefore(day, selectedStartDate)
      ? eachDayOfInterval({
          start: day,
          end: selectedStartDate,
        })
      : eachDayOfInterval({
          start: selectedStartDate,
          end: day,
        })
    if (
      period.some((day: Date) =>
        availabilities.some(
          (availability: CompanyHousingDateAvailability) =>
            format(day, 'yyyy-MM-dd') === availability.date &&
            !availability.is_available,
        ),
      )
    ) {
      // Between this date and selected date there are some unavailable dates
      return false
    }
  }
  return true
}

export const areSlotsConsecutive = (slots: MinimumTimeslot[]) =>
  !slots
    // First we sort the slots so they are in order by start time
    .sort((a, b) => (isBefore(new Date(a.start), new Date(b.start)) ? -1 : 1))
    .some((slot: MinimumTimeslot, index: number) => {
      const prevSlot = index === 0 ? null : slots[index - 1]
      if (
        prevSlot &&
        !isSameDay(new Date(slot.start), new Date(prevSlot.end))
      ) {
        // Ignore the case when previous slot is on previous day, this should be allowed
        return false
      }
      return prevSlot && prevSlot.end !== slot.start
    })

// Used for adding all slots until the clicked slot
export const findAllSlotsBetweenSlotAndSelectedSlots = (
  slot: MinimumTimeslot,
  selectedSlots: MinimumTimeslot[],
  slots: MinimumTimeslot[],
) => {
  if (!selectedSlots.length) return []

  const slotStart = new Date(slot.start)
  const firstSelectedSlotStart = new Date(selectedSlots[0].start)
  let betweenSlots: MinimumTimeslot[] = []
  if (isBefore(slotStart, firstSelectedSlotStart)) {
    betweenSlots = slots.filter(
      (s) =>
        isBefore(new Date(s.start), firstSelectedSlotStart) &&
        isAfter(new Date(s.start), slotStart),
    )
  }
  const lastSelectedSlotStart = new Date(
    selectedSlots[selectedSlots.length - 1].start,
  )
  if (isAfter(slotStart, lastSelectedSlotStart)) {
    betweenSlots = slots.filter(
      (s) =>
        isAfter(new Date(s.start), lastSelectedSlotStart) &&
        isBefore(new Date(s.start), slotStart),
    )
  }
  // Return slots that are not selected slots or clicked slot
  return filterOutSlots(betweenSlots, [...selectedSlots, slot])
}

// Used for selecting slots based on min_duration initially
export const findAllSlotsBetweenSlotAndMinDuration = (
  slot: MinimumTimeslot,
  slots: MinimumTimeslot[],
) => {
  const slotStart = new Date(slot.start)
  const minDurationEnd = addMinutes(slotStart, slot.min_duration as number)
  return slots.filter(
    (s) =>
      isAfter(new Date(s.start), slotStart) &&
      isBefore(new Date(s.start), minDurationEnd),
  )
}

// Used for removing all slots after the clicked slot
export const findAllOutOfBoundsSelectedSlots = (
  slot: MinimumTimeslot,
  selectedSlots: MinimumTimeslot[],
) => {
  return selectedSlots.filter(
    (s: MinimumTimeslot) => !isAfter(new Date(slot.start), new Date(s.start)),
  )
}

export const filterOutSlots = (
  slots: MinimumTimeslot[],
  slotsToFilterOut: MinimumTimeslot[],
) => slots.filter((slot) => !slotsToFilterOut.some((s) => _.isEqual(slot, s)))

export const mapEventSlots = (eventSlots: EventTimeSlot[]): MinimumTimeslot[] =>
  eventSlots.flatMap((slot: EventTimeSlot) =>
    !isSameDay(new Date(slot.start_at), new Date(slot.end_at))
      ? eachDayOfInterval({
          start: new Date(slot.start_at),
          end: new Date(slot.end_at),
        }).map(
          (day: Date) =>
            ({
              id: slot.id,
              start: isSameDay(new Date(slot.start_at), day)
                ? slot.start_at
                : format(startOfDay(day), 'yyyy-MM-dd HH:mm:ss'),
              end: isSameDay(new Date(slot.end_at), day)
                ? slot.end_at
                : format(endOfDay(day), 'yyyy-MM-dd HH:mm:ss'),
              duration: differenceInMinutes(endOfDay(day), startOfDay(day)),
            } as MinimumTimeslot),
        )
      : ({
          id: slot.id,
          start: slot.start_at,
          end: slot.end_at,
          duration: differenceInMinutes(
            new Date(slot.end_at),
            new Date(slot.start_at),
          ),
        } as MinimumTimeslot),
  )

export const mapTicketSlots = (
  ticketSlots: ITicketItemDateAvailabilityTimeslot[],
): MinimumTimeslot[] =>
  ticketSlots.flatMap((slot: ITicketItemDateAvailabilityTimeslot) => {
    if (
      !slot.sale_ends_at ||
      (slot.sale_ends_at && isAfter(new Date(slot.sale_ends_at), new Date()))
    ) {
      return {
        id: slot.id,
        start: slot.start,
        end: slot.end,
        duration: differenceInMinutes(new Date(slot.end), new Date(slot.start)),
      }
    }
    return []
  })
