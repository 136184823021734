import { Guests } from '@/models/public/service.model'
import router from '@/router'
import { ROUTE_PATHS } from '@/router/public/paths'
import { computed } from 'vue'

export const calculateHousingGuests = (
  adults: number,
  children: number,
  maxHousingUnits: number,
  maxGuestsPerUnit = 0,
): Guests[] => {
  // number of adults we must accommodate
  const adultsSearch = adults
  // number of children we must accommodate
  const childrenSearch = children
  // let's create empty array that will hold the data
  const housingUnitGuestsSearch: Guests[] = []
  // how many adults / children there are yet to accommodate
  let adultsSearchResidue = adultsSearch
  let childrenSearchResidue = childrenSearch

  Array.from(Array(maxHousingUnits).keys()).forEach(() => {
    // how many adults we are able to accommodate per unit
    let adultsPerUnit =
      adultsSearchResidue > maxGuestsPerUnit
        ? maxGuestsPerUnit
        : adultsSearchResidue

    // if there are children, put 1 adult less to a room, because we don't allow only children
    // except when that means 0 adults as a result
    if (
      childrenSearch > 0 &&
      adultsSearchResidue + childrenSearchResidue > maxGuestsPerUnit &&
      adultsPerUnit - 1 > 0
    ) {
      adultsPerUnit = adultsPerUnit - 1
    }

    // calculate how many children we can accommodate to remaining places
    let childrenPerUnit =
      childrenSearchResidue > maxGuestsPerUnit - adultsPerUnit
        ? maxGuestsPerUnit - adultsPerUnit
        : childrenSearchResidue

    const guestsObj = {
      adults: adultsPerUnit,
      children: childrenPerUnit,
    }
    // reassign variables to accommodate the rest
    adultsPerUnit = adultsSearchResidue - adultsPerUnit
    childrenPerUnit = childrenSearchResidue - childrenPerUnit

    adultsSearchResidue = adultsPerUnit
    childrenSearchResidue = childrenPerUnit

    // add to array only if there are positive values
    if (guestsObj.adults > 0 || guestsObj.children > 0) {
      housingUnitGuestsSearch.push(guestsObj)
    }
  })
  return housingUnitGuestsSearch
}

export const goToServicePublicDetails = (
  serviceHash: string,
  serviceSlug: string,
  refType: string | undefined,
  refCode: string | undefined,
) => {
  router.push({
    name: ROUTE_PATHS.SERVICES,
    params: {
      id: serviceHash,
      slug: serviceSlug,
    },
    query: {
      ref_type: refType,
      ref_code: refCode,
    },
  })
}

export const areNewServiceCategoriesEnabled = computed(
  () => process.env.VUE_APP_NEW_CATEGORIES_ENABLED,
)
