import { BackendEvent, DisplayEvent } from '@/models/google.model'
import { calendarStatusColors } from '@/utils/calendar'
import {
  add,
  differenceInMinutes,
  endOfWeek,
  isEqual,
  startOfWeek,
} from 'date-fns'
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import endOfDay from 'date-fns/endOfDay'
import endOfMonth from 'date-fns/endOfMonth'
import isBefore from 'date-fns/isBefore'
import isSameDay from 'date-fns/isSameDay'
import isWithinInterval from 'date-fns/isWithinInterval'
import startOfDay from 'date-fns/startOfDay'
import startOfMonth from 'date-fns/startOfMonth'
import _ from 'lodash'

export const MINIMUM_EVENT_DURATION = 15 // minutes

export const getWeekdaysForDate = (date: Date): Date[] => {
  const start = startOfWeek(date, { weekStartsOn: 1 })
  const end = endOfWeek(date, { weekStartsOn: 1 })
  return eachDayOfInterval({ start, end })
}

export const getMonthDaysForDate = (date: Date): Date[] => {
  const start = startOfWeek(startOfMonth(date), { weekStartsOn: 1 })
  const end = endOfWeek(endOfMonth(date), { weekStartsOn: 1 })
  return eachDayOfInterval({ start, end })
}

// Month period includes days from previous and next month too, to fill in the 5x7 grid
// This function gets the actual month the period is for
export const getActualMonthDaysInMonthPeriod = (days: Date[]): Date[] => {
  const intervalContaining = {
    start: startOfDay(days[0]),
    end: endOfDay(days[days.length - 1]),
  }
  for (const day of days) {
    const monthInterval = { start: startOfMonth(day), end: endOfMonth(day) }
    const isWholeMonthWithinPeriod = isIntervalWithinInterval(
      monthInterval,
      intervalContaining,
    )
    if (isWholeMonthWithinPeriod) {
      return eachDayOfInterval(monthInterval)
    }
  }
  return []
}

export const getSortedDaysEventsWithOverlapSteps = (
  events: DisplayEvent[],
  day: Date,
  multidayEventsInCalendar: boolean,
): DisplayEvent[] => {
  const daysEvents = multidayEventsInCalendar
    ? findEventsOverlappingDay(events, day)
    : findEventsHappeningWithinDay(events, day)
  const sortedEvents = getEventsSortedByStartTime(daysEvents)
  return getEventsWithOverlapSteps(sortedEvents)
}

export const doesEventOverlapDay = (
  event: DisplayEvent,
  day: Date,
  inclusive = true,
): boolean => {
  const dayStart = startOfDay(day)
  const dayEnd = endOfDay(day)
  return areIntervalsOverlapping(
    { start: dayStart, end: dayEnd },
    { start: event.start, end: event.end },
    // { inclusive },
  )
}

export const doEventDaysOverlap = (
  eventA: DisplayEvent,
  eventB: DisplayEvent,
  inclusive = true,
): boolean => {
  return areIntervalsOverlapping(
    { start: startOfDay(eventA.start), end: endOfDay(eventA.end) },
    { start: startOfDay(eventB.start), end: endOfDay(eventB.end) },
    { inclusive },
  )
}

export const doesMultidayEventOverlapPeriod = (
  eventA: DisplayEvent,
  start: Date,
  end: Date,
  inclusive = true,
): boolean => {
  return areIntervalsOverlapping(
    { start: startOfDay(eventA.start), end: endOfDay(eventA.end) },
    { start, end },
    { inclusive },
  )
}

export const isIntervalWithinInterval = (
  intervalWithin: Interval,
  intervalContaining: Interval,
): boolean =>
  isWithinInterval(intervalWithin.start, {
    start: intervalContaining.start,
    end: intervalContaining.end,
  }) &&
  isWithinInterval(intervalWithin.end, {
    start: intervalContaining.start,
    end: intervalContaining.end,
  })

const colorPalette = [
  '#7986CB',
  '#33B679',
  '#8E24AA',
  '#E67C73',
  '#F6BF26',
  '#F4511E',
  '#039BE5',
  '#616161',
  '#3F51B5',
  '#0B8043',
  '#D50000',
]

function hashStringToNumber(str: string) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

export function generateColor(seed: string): string {
  const hash = hashStringToNumber(seed)
  const colorIndex = Math.abs(hash) % colorPalette.length
  return colorPalette[colorIndex]
}

export const mapIntoDisplayEvent = (event: BackendEvent): DisplayEvent => {
  const startDate = new Date(event.start)
  let endDate = new Date(event.end)
  startDate.setSeconds(0)
  startDate.setMilliseconds(0)
  endDate.setSeconds(0)
  endDate.setMilliseconds(0)
  const eventDuration = differenceInMinutes(endDate, startDate)
  // Minimum duration of event in calendar is 15 minutes
  // Real start and end do not change, this is only for display purposes
  if (eventDuration < MINIMUM_EVENT_DURATION) {
    endDate = add(endDate, { minutes: MINIMUM_EVENT_DURATION - eventDuration })
  }
  return {
    ...event,
    realStart: new Date(event.start),
    realEnd: new Date(event.end),
    start: startDate,
    end: endDate,
    color: generateColor(event.calendar_id),
    orderColor: event.order_uuid
      ? calendarStatusColors[event.order_status][2]
      : '#FFF',
    allDayEvent: !event.start.includes('T'),
  }
}

// Attach track, overlapStep and width properties to multiday events
export const getStyledMultidayEvents = (
  tracks: DisplayEvent[][],
  days: Date[],
) => {
  const styledEvents: DisplayEvent[] = []
  tracks.forEach((track: DisplayEvent[], index: number) => {
    track.forEach((event: DisplayEvent) => {
      const styledEvent = { ...event }
      styledEvent.track = index
      days.forEach((day: Date) => {
        if (doesEventOverlapDay(event, day)) {
          styledEvent.length = (styledEvent.length || 0) + 1
          if (styledEvent.overlapStep === undefined) {
            styledEvent.overlapStep = _.indexOf(days, day)
          }
        }
      })
      styledEvents.push(styledEvent)
    })
  })
  return styledEvents
}

/*
This function returns day slots that have either single day events or gaps for multiday events

Arguments
  todaysSingleDayEvents: events that last only during the given day, displayed as single items
  tracks: multiday event tracks that we need to leave gaps for
  day: given day out of the monthly calendar
  maxEvents: maximum number of slots that fit in a day
*/
export const getDaySlots = (
  todaysSingleDayEvents: DisplayEvent[],
  tracks: DisplayEvent[][],
  day: Date,
  maxEvents?: number,
): (DisplayEvent | null | undefined)[] => {
  const dayEventSlots: (DisplayEvent | null | undefined)[] = []
  // First we fill the day slots with multiday event tracks
  tracks.forEach((track: DisplayEvent[], index: number) => {
    const foundEvent = track.find((event: DisplayEvent) =>
      doesEventOverlapDay(event, day),
    )
    // We assign null for slot that needs to be kept empty as it overlaps a filled track
    // We assign undefined for a slot that can be filled
    dayEventSlots[index] = foundEvent ? null : undefined
  })
  todaysSingleDayEvents.forEach((event: DisplayEvent) => {
    let foundPlace = false
    for (let slotIndex = 0; slotIndex < dayEventSlots.length; slotIndex++) {
      // If found a slot that can be filled with a single day event
      if (dayEventSlots[slotIndex] === undefined) {
        dayEventSlots[slotIndex] = event
        foundPlace = true
        break
      }
    }
    if (!foundPlace) {
      // If more events fit in the day, add them at the end of day slots
      if (maxEvents && dayEventSlots.length < maxEvents) {
        dayEventSlots.push(event)
      } else if (!maxEvents) {
        dayEventSlots.push(event)
      }
    }
  })
  return dayEventSlots
}

// Minimum parallel tracks required to show multiday events so they don't overlap
export const getMultidayEventTracksForPeriod = (
  events: DisplayEvent[],
  start: Date,
  end: Date,
  multidayEventsInTimeline: boolean,
): DisplayEvent[][] => {
  const newTracks: DisplayEvent[][] = []
  const multidayEvents = findMultidayEventsForPeriod(
    events,
    start,
    end,
    multidayEventsInTimeline,
  )
  multidayEvents.forEach((event) => {
    // A track where event could fit
    const foundTrackIndex = newTracks.findIndex((track) =>
      track.every((existingEvent) => !doEventDaysOverlap(existingEvent, event)),
    )
    if (foundTrackIndex > -1) {
      // If found a fitting track, add it there
      newTracks[foundTrackIndex].push(event)
    } else {
      // Otherwise create a new track
      newTracks.push([event])
    }
  })
  return newTracks
}

const findMultidayEventsForPeriod = (
  events: DisplayEvent[],
  start: Date,
  end: Date,
  multidayEventsInTimeline: boolean,
): DisplayEvent[] =>
  events.filter(
    (event: DisplayEvent) =>
      (multidayEventsInTimeline
        ? event.allDayEvent
        : !isSameDay(event.start, event.end) || event.allDayEvent) &&
      doesMultidayEventOverlapPeriod(event, start, end),
  )

// Find events that start and end within a day
export const findEventsHappeningWithinDay = (
  events: DisplayEvent[],
  day: Date,
): DisplayEvent[] => {
  const dayStart = startOfDay(day)
  const dayEnd = endOfDay(day)
  return events.filter(
    (event: DisplayEvent) =>
      !event.allDayEvent &&
      isIntervalWithinInterval(
        {
          start: event.start,
          end: event.end,
        },
        {
          start: dayStart,
          end: dayEnd,
        },
      ),
  )
}

// Find events that overlap a day in any way
export const findEventsOverlappingDay = (
  events: DisplayEvent[],
  day: Date,
): DisplayEvent[] => {
  return events.filter(
    (event: DisplayEvent) =>
      !event.allDayEvent && doesEventOverlapDay(event, day),
  )
}

// Sort events in the order of their start time (earlier first, later after)
// If events start at the same time, sort the one ending later before (so longer event is in the background)
const getEventsSortedByStartTime = (events: DisplayEvent[]): DisplayEvent[] =>
  [...events].sort((eventA, eventB) => {
    if (isBefore(eventA.start, eventB.start)) {
      return -1
    }
    if (isEqual(eventA.start, eventB.start)) {
      if (isBefore(eventB.end, eventA.end)) {
        return -1
      }
    }
    return 1
  })

// Calculate how much events overlap others and increase their overlap step when needed
// Overlap step is later used to know how much left offset to give event, so events behind would be visible
const getEventsWithOverlapSteps = (events: DisplayEvent[]) => {
  const mutableEvents = _.cloneDeep(events)
  mutableEvents.forEach((event: DisplayEvent, index: number) => {
    const prevEventsThatOverlap = mutableEvents.slice(0, index).filter(
      (prevEvent) =>
        isWithinInterval(event.start, {
          start: prevEvent.start,
          end: prevEvent.end,
        }) && !isEqual(event.start, prevEvent.end),
    )
    let maxPrevOverlapStep = 0
    prevEventsThatOverlap.forEach((prevEvent) => {
      if (prevEvent.overlapStep && prevEvent.overlapStep > maxPrevOverlapStep) {
        maxPrevOverlapStep = prevEvent.overlapStep
      }
    })
    if (prevEventsThatOverlap.length) {
      event.overlapStep = maxPrevOverlapStep + 1
    }
  })
  return mutableEvents
}
