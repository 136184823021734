import {
  IBaseServiceTicketItemPayload,
  ICreateServiceTicketItemPayload,
  IDeleteServiceTicketItemPayload,
  IFetchServiceTicketItemListPayload,
  IFetchServiceTicketItemPayload,
  IFetchServiceTicketTimelineDayDetails,
  IFetchServiceTicketTimelineDayOverview,
  IServiceTicketItem,
  IServiceTicketTimelineDayDetails,
  IServiceTicketTimelineDayOverview,
  ITicketItemDateAvailability,
  ITicketItemDateAvailabilityPayload,
  ITicketServiceItemPriceInformationPayload,
  ITicketServicePeriodAvailability,
  ITicketServicePeriodAvailabilityPayload,
  ITicketServicePriceInformation,
  IUpdateServiceTicketItemPayload,
  IUpdateServiceTicketTimelinePayload,
} from '@/models/service/serviceTicket.model'
import axios from 'axios'
import { Ref } from 'vue'
import { QueryClient, useMutation, useQuery, useQueryClient } from 'vue-query'

// Ticket order item endpoints can be found in @/queries/cart

const serviceTicketsKeys = {
  all: ['serviceTickets'] as const,
  lists: () => [...serviceTicketsKeys.all, 'list'] as const,
  ticketItemLists: () => [...serviceTicketsKeys.lists(), 'ticketList'] as const,
  ticketItemList: (companyId: number, serviceId: number) =>
    [
      ...serviceTicketsKeys.ticketItemLists(),
      { companyId, serviceId },
    ] as const,
  timelineTicketDayOverviewLists: () =>
    [...serviceTicketsKeys.lists(), 'timelineTicketDayOverviewList'] as const,
  timelineTicketDayOverviewList: (
    payload: Ref<IFetchServiceTicketTimelineDayOverview>,
  ) =>
    [...serviceTicketsKeys.timelineTicketDayOverviewLists(), payload] as const,
  timelineTicketDayDetailsLists: () =>
    [...serviceTicketsKeys.lists(), 'timelineTicketDayDetailsList'] as const,
  timelineTicketDayDetailsList: (
    payload: Ref<IFetchServiceTicketTimelineDayDetails>,
  ) =>
    [...serviceTicketsKeys.timelineTicketDayDetailsLists(), payload] as const,
}

const _invalidateTicketList = (
  queryClient: QueryClient,
  variables: IBaseServiceTicketItemPayload,
) => {
  queryClient.invalidateQueries(
    serviceTicketsKeys.ticketItemList(variables.companyId, variables.serviceId),
  )
  queryClient.invalidateQueries(
    serviceTicketsKeys.timelineTicketDayOverviewLists(),
  )
  queryClient.invalidateQueries(
    serviceTicketsKeys.timelineTicketDayDetailsLists(),
  )
}

const fetchServiceTicketItemList = async (
  payload: IFetchServiceTicketItemListPayload,
) => {
  const response = await axios.get<IServiceTicketItem[]>(
    `companies/${payload.companyId}/services/${payload.serviceId}/tickets/`,
  )
  return response.data
}

export function useFetchServiceTicketItemList() {
  const queryClient = useQueryClient()
  return useMutation(fetchServiceTicketItemList, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        serviceTicketsKeys.ticketItemList(
          variables.companyId,
          variables.serviceId,
        ),
        data,
      )
    },
  })
}

export function useFetchServiceTicketItemListQuery(
  payload: IFetchServiceTicketItemListPayload,
) {
  return useQuery(
    serviceTicketsKeys.ticketItemList(payload.companyId, payload.serviceId),
    async () => {
      return fetchServiceTicketItemList(payload)
    },
  )
}

const createServiceTicketItem = async (
  payload: ICreateServiceTicketItemPayload,
) => {
  const response = await axios.post<IServiceTicketItem>(
    `companies/${payload.companyId}/services/${payload.serviceId}/tickets/`,
    payload.data,
  )
  return response.data
}

export function useCreateServiceTicketItem() {
  const queryClient = useQueryClient()
  return useMutation(createServiceTicketItem, {
    onSuccess: (data, variables) => {
      _invalidateTicketList(queryClient, variables)
    },
  })
}

const fetchServiceTicketItem = async (
  payload: IFetchServiceTicketItemPayload,
) => {
  const response = await axios.get<IServiceTicketItem>(
    `companies/${payload.companyId}/services/${payload.serviceId}/tickets/${payload.ticketId}/`,
  )
  return response.data
}

export function useFetchServiceTicketItem() {
  return useMutation(fetchServiceTicketItem)
}

const updateServiceTicketItem = async (
  payload: IUpdateServiceTicketItemPayload,
) => {
  const response = await axios.put<IServiceTicketItem>(
    `companies/${payload.companyId}/services/${payload.serviceId}/tickets/${payload.ticketId}/`,
    payload.data,
  )
  return response.data
}

export function useUpdateServiceTicketItem() {
  const queryClient = useQueryClient()
  return useMutation(updateServiceTicketItem, {
    onSuccess: (data, variables) => {
      _invalidateTicketList(queryClient, variables)
    },
  })
}

const deleteServiceTicketItem = async (
  payload: IDeleteServiceTicketItemPayload,
) => {
  const response = await axios.delete(
    `companies/${payload.companyId}/services/${payload.serviceId}/tickets/${payload.ticketId}/`,
  )
  return response.data
}

export function useDeleteServiceTicketItem() {
  const queryClient = useQueryClient()
  return useMutation(deleteServiceTicketItem, {
    onSuccess: (data, variables) => {
      _invalidateTicketList(queryClient, variables)
    },
  })
}

const fetchServiceTicketTimelineDayOverviewList = async (
  payload: IFetchServiceTicketTimelineDayOverview,
) => {
  const response = await axios.get<IServiceTicketTimelineDayOverview[]>(
    `companies/${payload.companyId}/services/${payload.serviceId}/tickets/timeline/overview/`,
    {
      params: payload.params,
    },
  )
  return response.data
}

export function useFetchServiceTicketTimelineDayOverviewQuery(
  payload: Ref<IFetchServiceTicketTimelineDayOverview>,
) {
  return useQuery(
    serviceTicketsKeys.timelineTicketDayOverviewList(payload),
    async () => {
      return fetchServiceTicketTimelineDayOverviewList(payload.value)
    },
  )
}

const fetchServiceTicketTimelineDayDetailsList = async (
  payload: IFetchServiceTicketTimelineDayDetails,
) => {
  const response = await axios.get<{
    ticket_item: IServiceTicketItem
    days: IServiceTicketTimelineDayDetails[]
  }>(
    `companies/${payload.companyId}/services/${payload.serviceId}/tickets/${payload.ticketId}/timeline/details/`,
    {
      params: payload.params,
    },
  )
  return response.data
}

export function useFetchServiceTicketTimelineDayDetails() {
  return useMutation(fetchServiceTicketTimelineDayDetailsList)
}

export function useFetchServiceTicketTimelineDayDetailsQuery(
  payload: Ref<IFetchServiceTicketTimelineDayDetails>,
) {
  return useQuery(
    serviceTicketsKeys.timelineTicketDayDetailsList(payload),
    async () => {
      return fetchServiceTicketTimelineDayDetailsList(payload.value)
    },
  )
}

const updateServiceTicketTimeline = async (
  payload: IUpdateServiceTicketTimelinePayload,
) => {
  const response = await axios.put(
    `companies/${payload.companyId}/services/${payload.serviceId}/tickets/timeline/update/`,
    payload.data,
  )
  return response.data
}

export function useUpdateServiceTicketTimelineMutation() {
  const queryClient = useQueryClient()
  return useMutation(updateServiceTicketTimeline, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        serviceTicketsKeys.timelineTicketDayOverviewLists(),
      )

      queryClient.invalidateQueries(
        serviceTicketsKeys.timelineTicketDayDetailsLists(),
      )
    },
  })
}

export function useFetchTicketItemPriceInformationMutation() {
  return useMutation(
    async (payload: ITicketServiceItemPriceInformationPayload) => {
      // Preview endpoint is for service provider in service preview
      const serviceUrl = payload.isPreview
        ? `public/service/${payload.service}/ticket-information/preview/`
        : `public/service/${payload.service}/ticket-information/`

      const { data } = await axios.post<ITicketServicePriceInformation>(
        serviceUrl,
        payload.body,
      )
      return data
    },
  )
}

const fetchTicketServicePeriodAvailability = async (
  payload: ITicketServicePeriodAvailabilityPayload,
) => {
  const response = await axios.post<ITicketServicePeriodAvailability>(
    `public/service/${payload.hashId}/ticket-availability/`,
    payload.body,
  )
  return response.data
}

export function useFetchTicketServicePeriodAvailabilityMutation() {
  return useMutation(fetchTicketServicePeriodAvailability)
}

const fetchTicketItemDateAvailabilityList = async (
  payload: ITicketItemDateAvailabilityPayload,
) => {
  const response = await axios.post<ITicketItemDateAvailability[]>(
    `public/service/${payload.hashId}/ticket-detailed-availability/`,
    payload.body,
  )
  return response.data
}

export function useFetchTicketItemDateAvailabilityListMutation() {
  return useMutation(fetchTicketItemDateAvailabilityList)
}
