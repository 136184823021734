import { ServiceHashId } from '@/models/public/service.model'
import {
  StringDate,
  StringDateTime,
  StringDecimal,
  StringTime,
} from '@/models/shared.model'

// Ticket order item models can be found in @/models/public/order.model

export enum ServiceTicketType {
  TIMESLOT = 'TIMESLOT',
  DURATION = 'DURATION',
}

export type IServiceTicketItemId = number

export interface IServiceTicketItem {
  readonly id: IServiceTicketItemId
  type: ServiceTicketType
  name: string
  is_timeslot_duration: boolean
  duration: number // minutes
  ordinal: number
  prices: IServiceTicketItemDayPrice[]
  quantities: IServiceTicketItemDayQuantity[]
  slots: IServiceTicketItemDaySlot[]
  isGroupTicket: boolean | null
  groupSize: number | null
  is_archived: boolean
  can_delete: boolean
}

export interface IServiceTicketItemDate {
  readonly ticket_item: IServiceTicketItemId
  date_at: StringDate
  is_active: boolean
}

export interface IServiceTicketItemDayPrice {
  readonly id?: number
  price: StringDecimal
  start_time: StringTime | null
  end_time: StringTime | null
}

export interface IServiceTicketItemDayQuantity {
  readonly id?: number
  quantity: number
  start_time: StringTime | null
  end_time: StringTime | null
}

export interface IServiceTicketItemDaySlot {
  readonly id?: number
  start_time: StringTime | null
  end_time: StringTime | null
}

export interface IBaseServiceTicketItemPayload {
  companyId: number
  serviceId: number
}

export interface IBaseServiceTicketDetailsPayload
  extends IBaseServiceTicketItemPayload {
  ticketId: IServiceTicketItemId
}

export type IFetchServiceTicketItemListPayload = IBaseServiceTicketItemPayload

export interface ICreateServiceTicketItemPayload
  extends IBaseServiceTicketItemPayload {
  data: Omit<IServiceTicketItem, 'service'>
}

export type IFetchServiceTicketItemPayload = IBaseServiceTicketDetailsPayload

export interface IUpdateServiceTicketItemPayload
  extends IBaseServiceTicketDetailsPayload {
  data: Omit<IServiceTicketItem, 'service'>
}

export type IDeleteServiceTicketItemPayload = IBaseServiceTicketDetailsPayload

export interface IServiceTicketTimelineDayOverview {
  ticket_item: IServiceTicketItemId
  date_at: StringDate
  is_active: boolean
  min_price: StringDecimal | null
  max_price: StringDecimal | null
  warnings: string[]
}

export interface IServiceTicketTimelineDayDetails {
  ticket_item: IServiceTicketItemId
  date_at: StringDate
  prices: IServiceTicketItemDayPrice[]
  quantities: IServiceTicketItemDayQuantity[]
  slots: IServiceTicketItemDaySlot[]
}

interface ITimelinePeriodParams {
  /** Inclusive start date string. */
  start: StringDate | undefined
  /** Inclusive end date string. */
  end: StringDate | undefined
}

export interface IFetchServiceTicketTimelineDayOverview
  extends IBaseServiceTicketItemPayload {
  params: ITimelinePeriodParams
}

export interface IFetchServiceTicketTimelineDayDetails
  extends IBaseServiceTicketDetailsPayload {
  params: ITimelinePeriodParams
}

export interface IUpdateServiceTicketTimeline {
  ticket_items: number[]
  days: StringDate[]
  is_active: boolean
  use_default_prices: boolean
  prices: Omit<IServiceTicketItemDayPrice, 'id'>
  use_default_timeslots: boolean
  quantities: Omit<IServiceTicketItemDayQuantity, 'id'>
  use_default_quantities: boolean
  slots: Omit<IServiceTicketItemDaySlot, 'id'>
}

export interface IUpdateServiceTicketTimelinePayload
  extends IBaseServiceTicketItemPayload {
  data: IUpdateServiceTicketTimeline
}

export interface ITicketAvailabilitySearchPayload {
  date: StringDate
}

export interface ITicketAvailabilitySearch {
  start: StringDate | null
  end: StringDate | null
}

export interface ITicketServiceItemPriceInformationBodyItem {
  id?: number
  ticket_item: number
  start: StringDate
  end: StringDate
  quantity: number
}

export interface ITicketServiceItemPriceInformationBody {
  items: ITicketServiceItemPriceInformationBodyItem[]
  order_item: Record<string, number | null> | null
  group_service: ServiceHashId | undefined
}

export interface ITicketServiceItemPriceInformationPayload {
  service: ServiceHashId | number
  isPreview: boolean
  body: ITicketServiceItemPriceInformationBody
}

export interface ITicketServiceItemPriceInformation {
  price: number
  base_total: number
  discounted_total: number
}

export interface ITicketServicePriceInformation {
  base_total: number
  discounted_total: number
  items: ITicketServiceItemPriceInformation[]
}

export interface ITicketServicePeriodAvailabilityBody {
  start: StringDate | null
  end: StringDate | null
}

export interface ITicketServicePeriodAvailabilityPayload {
  hashId: ServiceHashId
  body: ITicketServicePeriodAvailabilityBody
}

export type ITicketServicePeriodAvailability = StringDate[]

export interface ITicketItemDateAvailabilityBody {
  date: StringDate
}

export interface ITicketItemDatePeriodAvailabilityBody {
  start: StringDate
  end: StringDate
}

export interface ITicketItemDateAvailabilityPayload {
  hashId: ServiceHashId
  body: ITicketItemDateAvailabilityBody | ITicketItemDatePeriodAvailabilityBody
}

export interface ITicketItemDateAvailabilityTimeslot {
  id: number
  ticket_item: number
  start: StringDateTime
  end: StringDateTime
  price: StringDecimal | number
  available_quantity: number
  sale_ends_at: StringDateTime | null
}

export interface ITicketItemDateAvailability {
  ticket_item: number
  type: ServiceTicketType
  name: string
  duration: number // total seconds
  is_duration_limited: boolean
  timeslots: ITicketItemDateAvailabilityTimeslot[]
}
